import { Radio, RadioGroup } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import classNames from "classnames";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";

import Referrals from "@/api/equifit/referrals";
import Selected from "@/assets/icons/radio-selected.svg";
import Unselected from "@/assets/icons/radio-unselected.svg";
import ContactConsentNotice from "@/components/ContactConsentNotice";
import { ACDL_GLOBAL_TRACKING } from "@/components/seo-and-analytics/adobe-data-layer/acdl-data-layer/global-tracking";
import { ACDL_LEAD_FLOW_TRACKING } from "@/components/seo-and-analytics/adobe-data-layer/acdl-data-layer/lead-flow";
import { COUNTRIES, LOCAL_STORAGE_KEYS, REGEX } from "@/constants";
import { UserContext } from "@/context/User";
import {
  CorpJoinFlowApi,
  CorpJoinFlowConstants,
  CorpJoinFlowUtils,
} from "@/features/corp-join-flow";
import { EqxForm, EqxTextInput } from "@/features/eqx-form";
import { CorpEmailNonEligibleBanner } from "@/features/join-flow";
import { LeadFlowUtils } from "@/features/lead-flow";
import { utils as userFlowUtils } from "@/features/user-flow";
import useNearbyClubs from "@/hooks/useNearbyClubs";
import { createItem } from "@/utils/helpers/localStorage";
import { ContactInfoFlowSchema } from "@/utils/helpers/validations";

import LeadFlowApi from "../api";
import { textContent } from "../config";
import { STEPS } from "../constants";
import { clearStoredState, getStoredState, updateStoredState } from "../utils";
import styles from "./ContactInformation.module.scss";

const ContactInformation = ({
  ctaEvent,
  currentFacility,
  description,
  formState,
  isEmbedded = false,
  isLoading,
  onGuidedWorkoutClick,
  onSelectClubClick,
  setFormState,
  setIsLoading,
  setNewDataLayer,
  showGuidedWorkoutCta = false,
  showJoinAction = true,
  showLeadAction = true,
  referringId,
  title,
}) => {
  const userContext = useContext(UserContext);
  const { isLoading: isLoadingNearbyClubs, nearbyClubs } = useNearbyClubs();
  const router = useRouter();
  const [contactFormIsValid, setContactFormIsValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [nearestClubs, setNearestClubs] = useState([]);
  const [showSuggestedLocations, setShowSuggestedLocations] = useState(false);

  const showNonEligibleBanner = getStoredState()?.showNonEligibleBanner;

  const { config, receiveTextAlerts, subscribeToNewsletter, step } = formState;

  const clubIsInUK =
    currentFacility?.country === COUNTRIES.UNITED_KINGDOM.abbreviation;

  const submitButtonIsEnabled =
    !isLoading &&
    !isLoadingNearbyClubs &&
    contactFormIsValid &&
    REGEX.FACILITY_ID.test(currentFacility?.facilityId);

  /**
   *
   */
  useEffect(() => {
    if (router.query.location) {
      let hasSelectedNearestClub = false;

      if (currentFacility?.facilityId) {
        hasSelectedNearestClub = nearbyClubs.slice(0, 3).some((nearestClub) => {
          return nearestClub.facilityId === currentFacility?.facilityId;
        });
      }

      if (currentFacility?.facilityId && !hasSelectedNearestClub) {
        setNearestClubs([currentFacility]);
      } else {
        setNearestClubs(nearbyClubs);
      }

      setShowSuggestedLocations(true);
    }
  }, [currentFacility, router?.query?.location, nearbyClubs]);

  /**
   *
   */
  useEffect(() => {
    const inputData = getStoredState();

    if (
      (router?.pathname === "/membership" ||
        !inputData?.suggestedFacility?.shortName) &&
      inputData?.contactInput === null
    ) {
      clearStoredState();
    }
  }, [router?.pathname]);

  /**
   *
   */
  useEffect(() => {
    if (ctaEvent?.length > 0 && currentFacility) {
      setNewDataLayer(
        ACDL_LEAD_FLOW_TRACKING.leadFlowCTA(ctaEvent, currentFacility)
      );
    }
  }, [ctaEvent, currentFacility, setNewDataLayer]);

  /**
   *
   */
  useEffect(() => {
    if (
      router.pathname !== "/membership" &&
      userContext.initialized &&
      !userContext.loading &&
      !isLoadingNearbyClubs
    ) {
      setNewDataLayer(
        ACDL_LEAD_FLOW_TRACKING.leadView(router.asPath, currentFacility)
      );
    }
  }, [
    currentFacility,
    isLoadingNearbyClubs,
    router.asPath,
    router.pathname,
    setNewDataLayer,
    userContext.initialized,
    userContext.loading,
  ]);

  const userIsInUK =
    (userContext.userLocation &&
      userContext.userLocation.location &&
      userContext.userLocation.location.is_eu) ||
    clubIsInUK;

  /**
   *
   */
  const handleUserOptInClick = () => {
    setFormState({
      ...formState,
      receiveTextAlerts: !receiveTextAlerts,
    });
  };

  /**
   *
   */
  const handleUserOptInClickUK = () => {
    setFormState({
      ...formState,
      subscribeToNewsletter: !subscribeToNewsletter,
    });
  };

  /**
   *
   */
  const handleFormValidityChange = (isValid) => {
    setContactFormIsValid(isValid);
  };

  /**
   *
   */
  const handleClubChange = (club) => {
    const isAcceptingAppointments = LeadFlowUtils.isClubAcceptingAppointments(
      club,
      config
    );
    let nextStep = step;

    if (step === STEPS.scheduleAppointment) {
      nextStep = isAcceptingAppointments ? step : STEPS.appointmentConfirmation;
    }

    setFormState({
      ...formState,
      selectedFacility: {
        ...club,
        isAcceptingAppointments,
      },
      step: nextStep,
    });

    LeadFlowUtils.handleAdobeTracking(club, setNewDataLayer);
  };

  const handleEmailCodeVerification = async (formState) => {
    const emailCodeVerifyApiResult =
      await CorpJoinFlowApi.createEmailCode(formState);

    if (emailCodeVerifyApiResult?.status === 200) {
      formState.corporateAccountId =
        emailCodeVerifyApiResult.data.corporateAccountId;

      formState.corporateContactId =
        emailCodeVerifyApiResult.data.corporateContactId;

      formState.companyName = emailCodeVerifyApiResult.data.name;

      CorpJoinFlowUtils.updateStoredState(formState);

      return router.push("/join/corp");
    }

    setErrorMessage(
      "An error has occurred. If you've just shared your email address with us, please wait a bit before trying again, or use a different one."
    );

    return;
  };

  /**
   *
   */
  const handleCreateCorpFlow = async (
    formValues,
    newState,
    leadTokenId = ""
  ) => {
    const eqxCurrentUrl = sessionStorage.getItem("eqxCurrentUrl");
    const eqxPreviousUrl = sessionStorage.getItem("eqxPreviousUrl");

    const nextFormstate = {
      contactInput: {
        "Company Email": formValues["Company Email (optional)"],
        "First Name": formValues["First Name"],
        "Last Name": formValues["Last Name"],
        "Personal Email": formValues["Email Address"],
        "Phone Number": formValues["Phone Number"],
        subscribeToNewsletter,
      },
      createdDate: new Date().getTime(),
      ctaLabel: "Visit A Club",
      hasCreatedCorp: null,
      isReferralFlow: !!referringId,
      leadTokenId,
      leadTransactionId: "",
      outreachCode: userFlowUtils.modifyOutreachCodeForVwoCampaign(
        newState.outreachCode
      ),
      receiveTextAlerts,
      referringCtaLabel: "Get Started",
      referringUrl: eqxPreviousUrl,
      scheduledFacility: null,
      selectedFacility: currentFacility,
      sourceUrl: eqxCurrentUrl,
      step: CorpJoinFlowConstants.STEPS.emailCodeConfirmation,
      suggestedFacility: null,
    };

    CorpJoinFlowUtils.updateStoredState(nextFormstate);

    if (referringId) {
      await handleEmailCodeVerification(nextFormstate);
    } else {
      const companyEmailApiResult = await CorpJoinFlowApi.createNewCorp(
        nextFormstate,
        setNewDataLayer
      );

      if (companyEmailApiResult?.status === 200) {
        nextFormstate.leadTransactionId = companyEmailApiResult?.TransactionId;

        await handleEmailCodeVerification(nextFormstate);
      } else {
        setErrorMessage(
          "An error has occurred. If you've just shared your email address with us, please wait a bit before trying again, or use a different one."
        );
      }
    }

    setFormState(newState);
  };

  /**
   *
   */
  const handleCTA = async (formValues, value) => {
    if (value === "workout") {
      onGuidedWorkoutClick(formValues);

      return;
    }

    const newState = {
      ...formState,
      contactInput: {
        ...formValues,
      },
    };

    setIsLoading(true);

    setFormState(newState);

    let createCorpLead = false;

    if (formValues["Company Email (optional)"]) {
      const verifyEmailResponse = await CorpJoinFlowApi.verifyValidCorpEmail(
        formValues["Company Email (optional)"],
        currentFacility?.facilityId
      );

      createCorpLead = verifyEmailResponse?.domainVerified;
      newState.showNonEligibleBanner = !verifyEmailResponse?.domainVerified;
    }

    if (createCorpLead) {
      let associationToken;

      if (referringId) {
        const res = await Referrals.createReferral({
          formState,
          formValues,
          receiveTextAlerts,
          setNewDataLayer,
          subscribeToNewsletter,
        });

        associationToken = res.associationToken;
      }

      handleCreateCorpFlow(formValues, newState, associationToken);
    } else {
      let leadTokenId = "";
      let leadTransactionId = "";

      if (referringId) {
        const res = await Referrals.createReferral({
          formState,
          formValues,
          receiveTextAlerts,
          setNewDataLayer,
          subscribeToNewsletter,
        });

        leadTokenId = res.associationToken;
      } else {
        const res = await LeadFlowApi.createNewLead(
          newState,
          setNewDataLayer,
          router,
          isEmbedded,
          true
        );

        leadTransactionId = res?.TransactionId;
      }

      setNewDataLayer(ACDL_GLOBAL_TRACKING.singleFlowJoinNow(router.asPath));

      createItem(LOCAL_STORAGE_KEYS.SINGLE_FLOW_FORM_STATE, {
        formState: {
          values: {
            companyEmail: formValues["Company Email (optional)"],
            email: formValues["Email Address"],
            firstName: formValues["First Name"],
            lastName: formValues["Last Name"],
            newsletter: receiveTextAlerts,
            phone: formValues["Phone Number"],
          },
          ...(formState?.referrerDetails && {
            referrerDetails: formState.referrerDetails,
          }),
        },
      });

      updateStoredState({
        ...newState,
        leadTokenId,
        leadTransactionId,
        step: STEPS.scheduleAppointment,
      });

      // WEB-3052 router.push(`/join/step1/${currentFacility.facilityId}`);
      window.location.href = `/join/step1/${currentFacility.facilityId}`;
    }
  };

  /**
   *
   */
  const setUpNextStep = async (apiResult, newState) => {
    if (apiResult) {
      let leadTokenId;

      if (currentFacility?.isAcceptingAppointments) {
        newState.selectedFacility = currentFacility;
        newState.step = STEPS.scheduleAppointment;
      } else {
        newState.scheduledFacility = currentFacility;
        newState.step = STEPS.appointmentConfirmation; // If the club is not accepting appointments, we want to skip the scheduling step

        if (!referringId) {
          leadTokenId = await LeadFlowApi.updateAbandonedLead({
            ...newState,
            leadTransactionId: newState?.leadTransactionId
              ? newState.leadTransactionId
              : apiResult.leadTransactionId || apiResult.transactionId,
          });
        }
      }

      if (leadTokenId) {
        newState.leadTokenId = leadTokenId;
      }

      if (apiResult?.associationToken) {
        newState.leadTokenId = apiResult?.associationToken;
      }

      newState.hasCreatedLead = true;

      newState.leadTransactionId = formState?.referrerDetails
        ? apiResult.associationToken
        : apiResult.leadTransactionId || apiResult.transactionId;
    } else {
      setErrorMessage(
        "An error has occurred. If you've just shared your email address with us, please wait a bit before trying again, or use a different one."
      );
    }

    setFormState({
      ...newState,
    });

    setIsLoading(false);
  };

  /**
   *
   */
  const handleCreateNewLead = async (newState) => {
    setIsLoading(true);

    const apiResult = newState?.leadTransactionId
      ? await LeadFlowApi.updateAbandonedLead({
          ...newState,
          leadTransactionId: newState.leadTransactionId,
        })
      : await LeadFlowApi.createNewLead(
          newState,
          setNewDataLayer,
          router,
          isEmbedded
        );

    setUpNextStep(apiResult, newState);

    setFormState({
      ...newState,
    });

    setIsLoading(false);
  };

  /**
   *
   */
  const handleFormSubmit = async (formValues) => {
    if (!REGEX.FACILITY_ID.test(currentFacility?.facilityId)) {
      return;
    }

    const newState = {
      ...formState,
      contactInput: {
        ...formValues,
      },
    };

    setIsLoading(true);
    setFormState(newState);

    let createCorpLead = false;

    if (formValues["Company Email (optional)"]) {
      const verifyEmailResponse = await CorpJoinFlowApi.verifyValidCorpEmail(
        formValues["Company Email (optional)"],
        currentFacility?.facilityId
      );

      createCorpLead = verifyEmailResponse?.domainVerified;
      newState.showNonEligibleBanner = !verifyEmailResponse?.domainVerified;
    }

    if (referringId) {
      const apiResponse = await Referrals.createReferral({
        formState,
        formValues,
        receiveTextAlerts,
        setNewDataLayer,
        subscribeToNewsletter,
      });

      if (createCorpLead) {
        handleCreateCorpFlow(
          formValues,
          newState,
          apiResponse.associationToken
        );
      } else {
        const apiResult = apiResponse?.result ? apiResponse : false;
        setUpNextStep(apiResult, newState);
      }
    } else {
      if (createCorpLead) {
        handleCreateCorpFlow(formValues, newState);
      } else {
        handleCreateNewLead(newState);
        setIsLoading(false);
      }
    }

    setNewDataLayer(
      ACDL_LEAD_FLOW_TRACKING.leadView(router.asPath, currentFacility)
    );
  };

  return (
    <section data-is="ContactInformation">
      <header className={styles.introduction}>
        {title && <h3 className={styles.title}>{title}</h3>}
        {description && <div className={styles.description}>{description}</div>}
      </header>
      {formState.redirectFromFormerMember && (
        <p className={styles.formerMemberRedirectMessage}>
          We&apos;re sorry. We were not able to locate your previous membership
          with the information provided. Please complete the form below and we
          will be in touch to assist you.
        </p>
      )}
      <EqxForm
        className={styles.eqxForm}
        onCTA={handleCTA}
        onSubmit={handleFormSubmit}
        onValidityChange={handleFormValidityChange}
        schema={ContactInfoFlowSchema({ optionalCompanyEmail: true })}
        values={{
          "Company Email (optional)":
            formState?.contactInput?.["Company Email (optional)"] || "",
          "Email Address": formState?.contactInput?.["Email Address"] || "",
          "First Name": formState?.contactInput?.["First Name"] || "",
          "Last Name": formState?.contactInput?.["Last Name"] || "",
          "Phone Number": formState?.contactInput?.["Phone Number"] || "",
          "Zip Code (optional)":
            formState?.contactInput?.["Zip Code (optional)"] || "",
        }}
      >
        {showNonEligibleBanner && (
          <span className={styles.bannerContainer}>
            <CorpEmailNonEligibleBanner />
          </span>
        )}

        <EqxTextInput
          className={classNames(styles.formField, styles.fieldFirstName)}
          label="First Name"
        />

        <EqxTextInput
          className={classNames(styles.formField, styles.fieldLastName)}
          label="Last Name"
        />

        <EqxTextInput
          className={styles.formField}
          label="Email Address"
          type="email"
        />

        <EqxTextInput
          className={classNames(styles.formField, styles.fieldCorporateEmail)}
          helperText="We offer exclusive corporate partnerships. Please add yours to see if you apply."
          label="Company Email (optional)"
          type="email"
        />

        <EqxTextInput
          className={styles.formField}
          inputProps={{ maxLength: 15 }}
          label="Phone Number"
          type="tel"
        />

        <EqxTextInput
          className={classNames(styles.formField, styles.fieldZipCode)}
          inputProps={{ maxLength: 10 }}
          label="Zip Code (optional)"
        />

        {!showSuggestedLocations && (
          <div
            className={styles.clubSelectContainer}
            data-blank={currentFacility ? currentFacility : !currentFacility}
            onClick={onSelectClubClick}
          >
            <div className={styles.clubNameText}>
              {currentFacility?.webName || "Select a club"}
            </div>
          </div>
        )}

        {showSuggestedLocations && (
          <>
            <div className={styles.nearestClubsContainer}>
              <div className={styles.location}>Location</div>

              <RadioGroup name="selectedClub">
                {nearestClubs.slice(0, 3).map((nearestClub) => {
                  return (
                    <label
                      className={styles.radioLabel}
                      key={nearestClub.facilityId}
                    >
                      {currentFacility?.facilityId ===
                      nearestClub?.facilityId ? (
                        <Selected />
                      ) : (
                        <Unselected />
                      )}

                      <Radio
                        onClick={() => handleClubChange(nearestClub)}
                        style={{ visibility: "hidden" }}
                        value={nearestClub}
                      />

                      {nearestClub.webName}
                    </label>
                  );
                })}
              </RadioGroup>
            </div>

            <button
              className={styles.otherLocation}
              name="LeadFlow:Steps:ContactInfo:ContactInformation - Choose Location"
              onClick={onSelectClubClick}
            >
              {nearestClubs.length > 0 || currentFacility?.facilityId ? (
                <span>Other Location</span>
              ) : (
                <span>Choose Location</span>
              )}
            </button>
          </>
        )}

        {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}

        <ContactConsentNotice className={styles.agreementText} />

        {showGuidedWorkoutCta && (
          <span className={styles.ctaButtonGroup}>
            <button
              className={styles.ctaButton}
              data-track="lead-flow:contact-info:lead-cta"
              disabled={!submitButtonIsEnabled}
              name="LeadFlow:Steps:ContactInfo:ContactInformation - Schedule a Guided Tour"
              type="submit"
            >
              Schedule a VIP Guided Tour
            </button>

            <button
              className={styles.ctaButton}
              data-is-cta="true"
              data-track="lead-flow:contact-info:workout-cta"
              disabled={!submitButtonIsEnabled || showNonEligibleBanner}
              name="LeadFlow:Steps:ContactInfo:ContactInformation - Schedule a Guided Workout"
              type="submit"
              value="workout"
            >
              Schedule a VIP Guided Workout
            </button>
          </span>
        )}

        {!showGuidedWorkoutCta && (showJoinAction || showLeadAction) && (
          <span className={styles.ctaButtonGroup}>
            {showLeadAction && (
              <button
                className={styles.ctaButton}
                data-track="lead-flow:contact-info:lead-cta"
                disabled={!submitButtonIsEnabled}
                name="LeadFlow:Steps:ContactInfo:ContactInformation - Visit A Club"
                type="submit"
              >
                Visit a Club
              </button>
            )}

            {showJoinAction && (
              <button
                className={styles.ctaButton}
                data-is-cta="true"
                data-track="lead-flow:contact-info:join-cta"
                disabled={!submitButtonIsEnabled || showNonEligibleBanner}
                name="LeadFlow:Steps:ContactInfo:ContactInformation - Join Online"
                type="submit"
                value="join"
              >
                Join Online
              </button>
            )}
          </span>
        )}

        {userIsInUK && (
          <>
            <FormControlLabel
              className={styles.checkboxLabel}
              control={
                <Checkbox
                  checked={subscribeToNewsletter}
                  className={styles.checkboxUk}
                />
              }
              label={textContent.ukOptInCBText}
              labelPlacement="end"
              onClick={handleUserOptInClickUK}
            />

            <small className={styles.ukLegalText}>
              {textContent.ukOptInText}
            </small>
          </>
        )}

        <FormControlLabel
          className={styles.checkboxLabel}
          control={
            <Checkbox checked={receiveTextAlerts} className={styles.checkbox} />
          }
          label={textContent.optInText}
          labelPlacement="end"
          onClick={handleUserOptInClick}
        />

        <small className={styles.legalText}>{textContent.termsText}</small>

        {userIsInUK && (
          <small className={styles.ukLegalSubText}>
            {textContent.ukLegalSubText}
          </small>
        )}
      </EqxForm>
    </section>
  );
};

ContactInformation.propTypes = {
  ctaEvent: PropTypes.string,
  currentFacility: PropTypes.shape({
    country: PropTypes.string,
    facilityId: PropTypes.string,
    isAcceptingAppointments: PropTypes.bool,
    webName: PropTypes.string,
  }),
  description: PropTypes.string,
  formState: PropTypes.object,
  isEmbedded: PropTypes.bool,
  isLoading: PropTypes.bool,
  onGuidedWorkoutClick: PropTypes.func,
  onSelectClubClick: PropTypes.func,
  referringId: PropTypes.string,
  setFormState: PropTypes.func,
  setIsLoading: PropTypes.func,
  setNewDataLayer: PropTypes.func,
  showGuidedWorkoutCta: PropTypes.bool,
  showJoinAction: PropTypes.bool,
  showLeadAction: PropTypes.bool,
  title: PropTypes.string,
};

export default ContactInformation;
