import Link from "next/link";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Cookies } from "react-cookie";

import FacilitiesApiClient from "@/api/client/entities/facilities";
import ChangeClubPanel from "@/components/ChangeClubPanel";
import Loader from "@/components/Loader";
import { getCurrencyCode } from "@/components/seo-and-analytics/adobe-data-layer/acdl-data-layer";
import {
  ACDL_JOIN_FLOW_TRACKING,
  JOIN_FLOW_MEMBERSHIP_TYPES,
} from "@/components/seo-and-analytics/adobe-data-layer/acdl-data-layer/join-flow";
import { COOKIES, LOCAL_STORAGE_KEYS, SESSION_STORAGE_KEYS } from "@/constants";
import { DigitalDataLayerContext } from "@/context/DigitalDataLayer";
import { LeadFlowConstants, LeadFlowUtils } from "@/features/lead-flow";
import { ExitFlowConfirmation, Headline } from "@/features/user-flow";
import dynamicContentReplacer from "@/utils/helpers/dynamic_content_replacer";
import { getItem, removeItem } from "@/utils/helpers/localStorage";
import getFilteredPlans from "@/utils/membership-plans/getFilteredPlans";

import ChangeClubButton from "../ChangeClubButton";
import CorpEmailNonEligibleBanner from "../CorpEmailNonEligibleBanner/CorpEmailNonEligibleBanner";
import MembershipPlans from "../MembershipPlans";
import styles from "./ChooseMembership.module.scss";

const ChooseMembership = ({
  data,
  formerMemberId,
  handleAcdlClubChangeEvent,
  isEmbedded,
  joinAbandoned,
  loading,
  nearbyClubs,
  openPanel,
  selectedClubDetails,
  selectedPlanId,
  setFmPaymentCookies,
  setJoinPaymentCookies,
  singleFlowErrorMessage,
  switchToStep,
  updatePlanDetails,
}) => {
  const hasFetchedPlanData = useRef(false);
  const isFetchingPlanData = useRef(false);
  const router = useRouter();
  const [plans, setPlans] = useState(null);
  const [isAvailableOnline, setIsAvailableOnline] = useState(true);
  const [isError, setIsError] = useState(false);
  const { setNewDataLayer } = useContext(DigitalDataLayerContext);
  const showNonEligibleBanner =
    LeadFlowUtils.getStoredState()?.showNonEligibleBanner;

  const leadFlowStoredState = LeadFlowUtils.getStoredState();

  const {
    headlineComponent,
    offlineClubsDescription,
    offlineHeadline,
    primaryCta,
  } = data.fields;

  const fetchAndUpdatePlans = useCallback(async () => {
    if (isFetchingPlanData.current) {
      return;
    }

    isFetchingPlanData.current = true;

    updatePlanDetails({ loading: true });

    const {
      error,
      isAvailableOnline,
      memberId,
      plans,
      promotionId,
      region,
      isPresale,
      facilityStatus,
      facilityOpenDate,
    } = await getFilteredPlans(
      selectedClubDetails.facilityId,
      null,
      formerMemberId
    );

    hasFetchedPlanData.current = true;

    setPlans(plans);
    setIsAvailableOnline(isAvailableOnline);
    setIsError(error);

    // add region to selectedClubDetails for consistency and accessibility for data
    updatePlanDetails({
      loading: false,
      selectedClubDetails: {
        ...selectedClubDetails,
        facilityOpenDate,
        facilityStatus,
        isPresale,
        memberId,
        promotionId,
        region,
      },
      selectedPlan: plans.length === 1 ? plans[0] : [],
    });

    isFetchingPlanData.current = false;
  }, [formerMemberId, selectedClubDetails, updatePlanDetails]);

  useEffect(() => {
    if (hasFetchedPlanData.current || (selectedPlanId && plans?.length > 0)) {
      return;
    }

    /* fetch plans automatically only when facilityId is passed on initial render
      otherwise preselect the nearest club and try fetching plans for the same
      if none of them is available render a option to selectClub.
    */
    if (selectedClubDetails?.facilityId) {
      fetchAndUpdatePlans();
    } else if (nearbyClubs?.length > 0) {
      updatePlanDetails({
        selectedClubDetails: nearbyClubs[0],
      });
    }
  }, [
    fetchAndUpdatePlans,
    nearbyClubs,
    plans,
    selectedClubDetails?.facilityId,
    selectedPlanId,
    updatePlanDetails,
  ]);

  const replaceDynamicContentOfPlan = (value) => {
    // title and description in contentful required to contain dynamic values.
    // So, these replacers are used as dynamic values which will be replaced by real values here.
    const { webName, region } = selectedClubDetails;
    const replacerConfig = {
      "{{CLUB_NAME}}": webName,
      "{{REGION}}": region,
    };

    return dynamicContentReplacer(value, replacerConfig);
  };

  useEffect(() => {
    if (!loading && selectedClubDetails.facilityId && !isAvailableOnline) {
      sessionStorage.setItem(
        SESSION_STORAGE_KEYS.NO_ONLINE_JOIN_MESSAGE,
        replaceDynamicContentOfPlan(offlineClubsDescription)
      );

      LeadFlowUtils.updateStoredState({
        ...leadFlowStoredState,
        step: LeadFlowConstants.STEPS.scheduleAppointment,
      });

      router.push("/inquiry/schedule-your-visit");
    }
  }, [isAvailableOnline, loading, selectedClubDetails.facilityId]); // eslint-disable-line react-hooks/exhaustive-deps -- WEB-2321

  const handleFormSubmit = async (e) => {
    e && e.preventDefault();

    if (new Cookies().get("fmJoinToken")) {
      // if fmJoinToken is available then after updating the club and plan , user will redirect again to payment app
      await setFmPaymentCookies();
    }

    if (new Cookies().get(COOKIES.PAYMENT_JOIN_PRODUCT_TOKEN.name)) {
      // if joinToken is available then user will be redirected paymentApp after changing club or plan
      await setJoinPaymentCookies();
    }

    const actualPlan = plans.find((p) => p.planId === selectedPlanId);
    const isSingleClub = selectedPlanId === 15;

    const planTitle =
      selectedPlanId &&
      actualPlan &&
      actualPlan.description
        .replace("{{REGION}}", selectedClubDetails.region)
        .replace("{{CLUB_NAME}}", selectedClubDetails.webName);

    const {
      contactInformation: { country },
    } = await FacilitiesApiClient.findById(selectedClubDetails.facilityId);

    const ACDLMembershipSelectedInfo =
      ACDL_JOIN_FLOW_TRACKING.joinFlowMembershipSelected(
        `${
          isSingleClub
            ? JOIN_FLOW_MEMBERSHIP_TYPES.SINGLE
            : JOIN_FLOW_MEMBERSHIP_TYPES.MULTIPLE
        } | ${planTitle}`.toLowerCase(),
        `${actualPlan.cost.monthlyFee}`,
        getCurrencyCode(country),
        actualPlan
      );
    setNewDataLayer(ACDLMembershipSelectedInfo);

    // Single Flow
    const formData = getItem(LOCAL_STORAGE_KEYS.SINGLE_FLOW_FORM_STATE);

    removeItem(LOCAL_STORAGE_KEYS.SINGLE_FLOW_FORM_STATE);

    if (joinAbandoned && formData) {
      joinAbandoned(formData, {});
    } else {
      switchToStep(2);
    }
  };

  const handleClubChange = async (selectedClubDetails) => {
    if (handleAcdlClubChangeEvent) {
      handleAcdlClubChangeEvent(selectedClubDetails);
    }

    updatePlanDetails({
      selectedClubDetails,
      selectedPlan: {
        planId: null,
      },
    });

    hasFetchedPlanData.current = false;

    openPanel(null);
  };

  return (
    <section className={styles.chooseMembership}>
      {!isEmbedded && <ExitFlowConfirmation skipConfirmation={true} />}

      <Headline
        className={styles.headline}
        eyebrow="Step 1/3"
        finePrint={headlineComponent?.fields.finePrint}
        photo={headlineComponent?.fields.image}
        subcopy={headlineComponent?.fields.subcopy}
        title={
          isAvailableOnline ? headlineComponent?.fields.title : offlineHeadline
        }
      />

      <article className={styles.membershipOptions}>
        <p className={styles.lede}>
          Enjoy unlimited in-club and digital classes, access to EQX+ app, a
          complimentary fitness assessment and more.
        </p>

        {showNonEligibleBanner && <CorpEmailNonEligibleBanner />}

        {loading && <Loader />}

        {!loading && !selectedClubDetails?.facilityId && (
          <div className={styles.changeClubOption}>
            <ChangeClubButton
              handleClick={() =>
                openPanel(
                  <ChangeClubPanel
                    joinFlow={true}
                    onChangeClub={handleClubChange}
                  />
                )
              }
            >
              Select club
            </ChangeClubButton>
          </div>
        )}

        {!loading && selectedClubDetails.facilityId && isAvailableOnline && (
          <form className={styles.plansForm} onSubmit={handleFormSubmit}>
            <MembershipPlans
              handleClubChange={handleClubChange}
              key="plans"
              openPanel={openPanel}
              plans={plans}
              replaceDynamicContentOfPlan={replaceDynamicContentOfPlan}
              selectedClubDetails={selectedClubDetails}
              selectedPlanId={selectedPlanId}
              updatePlanDetails={updatePlanDetails}
            />

            {isError && (
              <div className={styles.errorMessageContainer}>
                <div className={styles.errorMessage}>
                  There was an error processing your request. Please try again
                  later.
                </div>
              </div>
            )}

            <div className={styles.ctaWrapper}>
              {!isEmbedded && (
                <div className={styles.rejoinMessage}>
                  Want to visit the club first?{" "}
                  <Link href="/inquiry/schedule-your-visit">
                    <a>Schedule a Visit</a>
                  </Link>
                </div>
              )}

              <button
                data-adobe="joinSubmitStep1"
                disabled={!selectedPlanId}
                name="JoinFlow:ChooseMembership - Submit Step 1"
                type="submit"
              >
                {primaryCta.fields.text}
              </button>

              {singleFlowErrorMessage && singleFlowErrorMessage.length > 0 && (
                <div className={styles.errorMessage}>
                  {singleFlowErrorMessage}
                </div>
              )}
            </div>
          </form>
        )}
      </article>
    </section>
  );
};

ChooseMembership.propTypes = {
  data: PropTypes.object,
  formerMemberId: PropTypes.string,
  handleAcdlClubChangeEvent: PropTypes.func,
  isEmbedded: PropTypes.bool,
  joinAbandoned: PropTypes.func,
  loading: PropTypes.bool,
  nearbyClubs: PropTypes.array,
  openPanel: PropTypes.func,
  selectedClubDetails: PropTypes.object,
  selectedPlanId: PropTypes.number,
  setFmPaymentCookies: PropTypes.func,
  setJoinPaymentCookies: PropTypes.func,
  singleFlowErrorMessage: PropTypes.string,
  switchToStep: PropTypes.func,
  updatePlanDetails: PropTypes.func,
};

ChooseMembership.defaultProps = {
  formerMemberId: "",
  isEmbedded: false,
};

export default ChooseMembership;
